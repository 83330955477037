<template>
  <b-row>
    <b-col cols="12">
      <!-- Default Colors -->
      <b-card
        title="Import SKU"
        class="background-color"
      >
        <b-row>
          <b-col md="4">
            <a href="https://docs.google.com/spreadsheets/d/11PfrTpZ_Imqo-sJa6We0cZMPkUWl88UWeknuRo9YHzM/" target="_blank"><u>Accéder au template</u></a>
            <h5 class="mt-1">Fichier :</h5>
            <b-form-file
              v-model="file"
              placeholder="Choisir un fichier ou glisser..."
              drop-placeholder="Drop fichier ici..."
              accept=".csv"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="text-center mt-2"
            md="2"
          >
            <b-form-group>
              <b-button
                variant="primary"
                :disabled="loading"
                @click="upload"
              >
                Lancer l'import
              </b-button>
            </b-form-group>
          </b-col>
          <b-col
            v-if="errors && errors.length"
            class="text-center mt-2"
            md="4"
          >
            <download-csv
              :data="errors"
              name="errors.csv"
            >
              <b-button
                class="mb-2"
                variant="warning"
              >
                Télécharger les {{ errors.length }} erreurs
              </b-button>
            </download-csv>
          </b-col>
        </b-row>
      </b-card>
      <!--/ Gradient color start -->
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton, BFormFile,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import JsonCSV from 'vue-json-csv'

export default {
  components: {
    BCard,
    BFormFile,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    'download-csv': JsonCSV,
  },
  data() {
    return {
      file: null,
      rangeDate: null,
      selected: '',
      option: ['desktop', 'mobile'],
      products: [],
      selectedProduct: '',
      bls: [],
      errors: [],
      loading: false,
    }
  },
  methods: {
    async upload(f) {
      this.errors = []
      if (!this.file) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous devez mettre un fichier.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        console.log(f)
        setTimeout(() => {}, 2000)
        const form = new FormData()
        form.append('file', this.file, this.file.name)
        try {
          this.loading = true
          const { errors } = await this.$http.post('/admin/logistic/compose-upload', form)
          this.file = null
          this.errors = errors
          console.log(errors)
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: `L'import a bien reussi. ${errors.length ? `Mais il y a eu ${errors.length} erreurs.` : ''}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
        }
      }
    },
    async removeItem(item) {
      try {
        console.log(item)
        this.loading = true
        await this.$http.put('/admin/logistic/bl', { sku: item.sku, id: item.id })
        this.loading = false
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Suppression réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.getBl()
      } catch (err) {
        console.log(err)
      }
      console.log(item)
    },
  },
}
</script>

  <style lang="scss">
  .colors-list {
    li {
      padding: 0.4rem;
      svg {
        margin-right: 0.25rem;
      }
    }
  }
  </style>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
